
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "NotificationsSettingsModal",
  data() {
    return {
      data: {
        popup_notification: "",
        event_reminders: "",
        email_notification: ""
      },
      btnLoading: false,
      errors: {},
      notifications: [
        {
          label: "Pop-up notifications",
          icon: "popup-notification",
          model: "popup_notification",
          types: [
            {
              id: 1,
              name: "On"
            },
            {
              id: 0,
              name: "Off"
            }
          ]
        },
        {
          label: "Event Reminders",
          icon: "event-reminder",
          model: "event_reminders",
          types: [
            {
              id: 1,
              name: "On"
            },
            {
              id: 0,
              name: "Off"
            }
          ]
        },
        {
          label: "Email notifications",
          icon: "email-notification",
          model: "email_notification",
          types: [
            {
              id: "default",
              name: "Default"
            },
            {
              id: "once_a_day",
              name: "Once a day"
            },
            {
              id: "off",
              name: "Off"
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted(): void {
    this.mapKeys();
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;
      this.errors = {};

      this.$store
        .dispatch("profile/UPDATE_NOTIFICATION_SETTING", this.data)
        .then(() => {
          this.btnLoading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          //@ts-ignore
          this.$refs["notifications-modal"].hide();
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      if (this.profile?.notification_settings) {
        for (const i of Object.keys(this.profile.notification_settings)) {
          if (i in this.data) {
            //@ts-ignore
            this.data[i] = this.profile.notification_settings[i];
          }
        }
      }
    }
  }
});
