
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileSettingsModal",
  data() {
    return {
      url: "",
      submitBtnLoading: false,
      data: {
        first_name: "",
        last_name: "",
        image: "",
        short_description: "",
        website: "",
        bio: "",
        social_links: {
          instagram: "",
          facebook: "",
          linkedin: "",
          twitter: ""
        } as any
      },
      errors: {}
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted(): void {
    this.mapKeys();
  },
  methods: {
    onSubmit() {
      this.submitBtnLoading = true;

      this.$store
        .dispatch("profile/UPDATE_PROFILE", this.data)
        .then(() => {
          this.submitBtnLoading = false;
          this.data.image = "";
          this.$store.dispatch("profile/GET_PROFILE");
          //@ts-ignore
          this.$refs["profile-modal"].hide();

          this.errors = {};
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    linkReplace(link: string) {
      const patters = ["http://", "https://", "www.", ".com/", [link]];
      let linkData = this.data?.social_links[link];
      for (const pattern of patters) {
        linkData = linkData.replace(pattern, "");
      }
      return linkData;
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      for (const i of Object.keys(this.data)) {
        if (i in this.profile) {
          //@ts-ignore
          this.data[i] = this.profile[i] ? this.profile[i] : "";
        } else if (i === "social_links") {
          console.log(
            "this.profile.all_social_links",
            this.profile.full_social_links
          );

          // this.data[i] = this.profile.all_social_links;

          if (this.profile?.full_social_links?.length) {
            for (const url of this.profile?.full_social_links) {
              this.data[i][url.name] = url.url;
            }
          }
        }
      }
      this.url = this.profile.profile_link;
    }
  }
});
