
import Vue from "vue";
import PrivacySettingsModal from "@/components/Modals//PrivacySettingsModal.vue";
import ProfilePhotoModal from "@/components/Modals//ProfilePhotoModal.vue";
import ProfileSettingsModal from "@/components/Modals/ProfileSettingsModal.vue";
import UserInformationSettingsModal from "@/components/Modals/UserInformationSettingsModal.vue";
import NotificationsSettingsModal from "@/components/Modals/NotificationsSettingsModal.vue";
import { mapGetters } from "vuex";
import StripeModal from "@/components/Modals/StripeModal.vue";
import MentoreeSignUpToMentor from "@/components/SignUp/MentoreeSignUpToMentor.vue";
import AdminPanelLoading from "@/components/AdminPanelLoading.vue";
import DeleteAccountModal from "@/components/Modals/DeleteAccountModal.vue";

export default Vue.extend({
  name: "AdminPanel",
  components: {
    PrivacySettingsModal,
    ProfileSettingsModal,
    UserInformationSettingsModal,
    NotificationsSettingsModal,
    StripeModal,
    MentoreeSignUpToMentor,
    AdminPanelLoading,
    DeleteAccountModal,
    ProfilePhotoModal
  },
  props: {
    profile: Object
  },
  data() {
    return {
      loading: true,
      modalMode: "edit",
      userInformation: {
        Expertise: {
          label: (this as any).$t("profile.categoriesExpertise"),
          icon: "expertise",
          isCategory: true
        },
        Title: {
          label: (this as any).$t("profile.categoriesTitle"),
          icon: "title",
          isCategory: true
        },
        Institution: {
          label: (this as any).$t("profile.categoriesInstitution"),
          icon: "institution",
          isCategory: true
        },
        location: {
          label: (this as any).$t("profile.userInformationLocationLabel"),
          icon: "marker"
        },
        language: {
          label: (this as any).$t("profile.userInformationSpokenLanguageLabel"),
          icon: "language-block"
        },
        timezone_id: {
          label: (this as any).$t("profile.userInformationTimeZoneLabel"),
          icon: "time-zone",
          isId: true,
          array: "timezones"
        },
        gender_id: {
          label: (this as any).$t("profile.userInformationGenderLabel"),
          icon: "gender",
          isId: true,
          array: "genders"
        }
      },
      notifications: {
        popup_notification: {
          label: (this as any).$t("profile.notificationsPopUp"),
          icon: "popup-notification"
        },
        event_reminders: {
          label: (this as any).$t("profile.notificationsEventReminders"),
          icon: "event-reminder"
        },
        email_notification: {
          label: (this as any).$t("profile.notificationsEmailNotifications"),
          icon: "email-notification"
        }
      },
      statistics: {
        bookedSessions: {
          label: (this as any).$t("profile.statisticsBookedSessions"),
          icon: "profile"
        },
        totalMentors: {
          label: (this as any).$t("profile.statisticsTotalMentors"),
          icon: "badge"
        },
        instantSessions: {
          label: (this as any).$t("profile.statisticsInstantSessions"),
          icon: "star-rounded"
        },
        rating: {
          label: (this as any).$t("profile.statisticsRating"),
          icon: "badge"
        },
        mentoringSessions: {
          label: (this as any).$t("profile.statisticsMentoringSessions"),
          icon: "users"
        },
        mentorcasts: {
          label: (this as any).$t("profile.statisticsMentorcasts"),
          icon: "badge"
        }
      },
      cardTypeOptions: [
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "visa",
          icon: "visa"
        },
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "paypal",
          icon: "paypal"
        },
        {
          label: (this as any).$t("mentorcastHero.cardTypeOptionDebitCart"),
          value: "mastercard",
          icon: "mastercard"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      genders: "helper/getGenders",
      languages: "helper/getLanguages",
      timezones: "helper/getTimezones",
      privacies: "privacy/getPrivacies",
      payment_methods: "stripePayment/getPaymentMethods"
    })
  },
  created() {
    Promise.all([
      this.$store.dispatch("category/GET_CATEGORIES"),
      this.$store.dispatch("helper/GET_LANGUAGES"),
      this.$store.dispatch("helper/GET_TIMEZONES"),
      this.$store.dispatch("helper/GET_GENDERS"),
      this.$store.dispatch("stripePayment/GET_PAYMENT_METHODS"),
      this.$store.dispatch("privacy/GET_PRIVACIES")
    ]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    getIdValue(item: any, array: any, key = "name") {
      if (
        (this as any)[array as any] &&
        (this as any)[array as any].length > 0
      ) {
        const index = (this as any)[array].findIndex((i: any) => i.id == item);
        if (index > -1) {
          return (this as any)[array][index][key];
        }
      }
      return;
    },
    profileRoute() {
      const path = "/profile";
      if (this.$route.path !== path) this.$router.push(path);
    },
    async openAddModal() {
      this.$bvModal.show("stripe");
      //@ts-ignore
      await this.$refs.stripe.loadStripe();
    },
    getCategoryType(key: string): any {
      if (this.profile?.category_types[key]) {
        return this.profile.category_types[key].join(", ");
      }
    },
    getIconType(type: string) {
      if (this.cardTypeOptions.find(i => i.value === type)) {
        //@ts-ignore
        return this.cardTypeOptions.find(i => i.value === type).icon;
      }
      return "paypal";
    },
    getPrivacyType(privacy: string) {
      return this.profile?.privacies[privacy]?.name;
    },
    getNotificationSettingValue(key: any) {
      const values = {
        1: "On",
        0: "Off",
        off: "Off",
        default: "Default",
        once_a_day: "1 per day"
      };

      if (this.profile && this.profile.notification_settings) {
        //@ts-ignore
        return values[this.profile.notification_settings[key] as any];
      }
    },
    deletePaymentMethod(id: string) {
      this.$store.dispatch("stripePayment/DELETE_PAYMENT_METHOD", id);
    },
    becomeMentor() {
      this.$bvModal.show("signup_invitation");
    }
  }
});
