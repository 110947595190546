
import Vue from "vue";
import { mapGetters } from "vuex";
import CategoryComponent from "@/components/Partials/CategoryComponent.vue";
import _ from "lodash";
import Fuse from "fuse.js";

export default Vue.extend({
  name: "UserInformationSettingsModal",
  components: {
    CategoryComponent
  },
  data() {
    return {
      data: {
        action_type: "panel",
        Expertise: [] as Array<string>,
        Title: [],
        // Specialization: [],
        Institution: [],
        location: "",
        language_ids: [],
        currency_id: "",
        timezone_id: "",
        gender_id: ""
      } as any,
      errors: {},
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      },
      submitBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      categories: "category/getCategories",
      languages: "helper/getLanguages",
      timezones: "helper/getTimezones",
      genders: "helper/getGenders",
      locations: "helper/getLocations"
    })
  },
  mounted(): void {
    this.mapKeys();
  },
  methods: {
    onSearch(search: any, loading: any) {
      if (search.length >= 3) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading: any, search: any, vm: any) => {
      vm.$store.dispatch("helper/GET_LOCATIONS", search).then(() => {
        loading(false);
      });
    }, 150),
    onSubmit() {
      this.submitBtnLoading = true;

      this.$store
        .dispatch("profile/UPDATE_INFORMATION", this.data)
        .then(() => {
          this.submitBtnLoading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          //@ts-ignore
          this.$refs["user-info-modal"].hide();
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      this.data.currency_id = this.profile.currency_id;
      this.data.location = this.profile.location;
      this.data.timezone_id = this.profile.timezone_id;
      this.data.gender_id = this.profile.gender_id;
      this.data.language_ids = this.profile.language_ids;
      if (this.profile?.category_types) {
        for (const i of Object.keys(this.profile.category_types)) {
          if (i in this.data) {
            this.data[i] = this.profile.category_types[i];
          }
        }
      }
    },
    addCategory(type: any, category: string) {
      if (type == "Diploma") type = "Title";
      this.data[type].push(category);
    },
    deleteCategory(type: any, index: number) {
      if (type == "Diploma") type = "Title";
      this.data[type].splice(index, 1);
    },
    fuseSearch(options: any, search: any) {
      const fuse = new Fuse(options, {
        keys: ["name", "native_name"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }: any) => item)
        : options;
    }
  }
});
