
import Vue from "vue";

export default Vue.extend({
  name: "DeleteAccountModal",
  props: {},
  data() {
    return {
      btnLoading: false,
      isDeletable: true
    };
  },
  mounted() {
    this.checkIsDeletable();
  },
  methods: {
    checkIsDeletable() {
      this.$store.dispatch("profile/CHECK_ACCOUNT_DELETE").then(data => {
        if (!data.is_true) {
          this.isDeletable = false;
        }
      });
    },
    onSubmit() {
      this.btnLoading = true;
      this.$store
        .dispatch("profile/POST_ACCOUNT_DELETE")
        .then(message => {
          this.btnLoading = false;
          //@ts-ignore
          this.$alertify.success(message);
          this.$store
            .dispatch("auth/LOGOUT_USER")
            .then(() => this.$router.push("/"));
        })
        .catch(() => {
          this.btnLoading = false;
        });
    }
  }
});
