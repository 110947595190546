
import Vue from "vue";

export default Vue.extend({
  name: "CategoryComponent",
  props: {
    title: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    options: {
      type: Array
    },
    maxInputLength: {
      type: Number,
      default: 75
    }
  },
  data() {
    return {
      isShow: false,
      data: {
        category: ""
      },
      error: ""
    };
  },
  computed: {
    getOptions(): any {
      return this.data.category.trim().length
        ? this.searchInArray(this.data.category, this.options)
        : this.options;
    }
  },
  methods: {
    clearError() {
      this.error = "";
    },
    addCategory() {
      if (
        this.data.category.trim().length === 0 ||
        this.categories.length >= this.max
      ) {
        return;
      }
      if (this.categories.includes(this.data.category.trim())) {
        this.error = `${this.title} ${(this as any).$t(
          "category.categoryMustUniqueErrorMessage"
        )}`;
        return;
      }
      this.$emit("addCategory", this.title, this.data.category);
      this.data.category = "";
      this.isShow = false;
    },
    deleteCategory(index: number) {
      this.$emit("deleteCategory", this.title, index);
    },
    addCategoryToInput(name: any) {
      this.data.category = name;
      this.isShow = false;
    },
    searchInArray(searchQuery: any, array: any) {
      return array?.filter((d: any) => {
        //@ts-ignore
        const data = d; //Incase If It's Array Of Objects.
        //@ts-ignore
        const dataWords =
          typeof data == "string" &&
          (data
            ?.split(" ")
            ?.map(b => b && b.toLowerCase().trim())
            .filter(b => b) as any);
        //@ts-ignore
        const searchWords =
          typeof searchQuery == "string" &&
          searchQuery
            ?.split(" ")
            .map(b => b && b.toLowerCase().trim())
            .filter(b => b);
        //@ts-ignore
        const matchingWords = searchWords.filter(
          (word: any) =>
            dataWords.findIndex((dataWord: any) => {
              return dataWord.includes(word);
            }) > -1
        );
        return matchingWords.length;
      });
    }
  }
});
