
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PrivacySettingsModal",
  data() {
    return {
      data: {
        privacy_type_ids: {}
      } as any,
      errors: {} as any,
      isRender: true,
      submitBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      privacies: "privacy/getPrivacies"
    })
  },
  watch: {
    profile() {
      if (this.profile && "id" in this.profile) {
        this.mapKeys();
      }
    }
  },
  created() {
    this.$store.dispatch("privacy/GET_PRIVACIES");
  },
  methods: {
    onSubmit() {
      this.submitBtnLoading = true;

      this.$store
        .dispatch("profile/UPDATE_PRIVACY", this.data)
        .then(() => {
          this.submitBtnLoading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          // @ts-ignore
          this.$refs["privacy-modal"].hide();
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      for (const i in this.profile.privacies) {
        const data = {
          [i]: this.profile.privacies[i].id
        };
        //@ts-ignore
        this.data.privacy_type_ids = { ...this.data.privacy_type_ids, ...data };
      }
    },
    useRecommended() {
      this.isRender = false;
      for (const privacy of this.privacies) {
        const index = privacy.types.findIndex((i: any) =>
          i.name.includes("Mentorice Community")
        );
        if (index > -1) {
          this.data.privacy_type_ids[privacy.name] = privacy.types[index].id;
        }
      }
      this.isRender = true;
    }
  }
});
