
import { mapGetters } from "vuex";
import Vue from "vue";
import ClipperModal from "@/components/ClipperModal.vue";

export default Vue.extend({
  name: "ProfilePhotoModal",
  components: {
    ClipperModal
  },
  props: {
    hasGuestUser: {
      type: Boolean
    }
  },
  data() {
    return {
      url: "",
      data: {
        image: ""
      },
      errors: {} as any,
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted(): void {
    this.mapKeys();
  },
  methods: {
    showImageUpload() {
      this.$bvModal.show("clipper");
    },
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    onSubmit() {
      if (this.hasGuestUser) {
        this.$emit("image", {
          url: this.url,
          image: this.data.image
        });
        return;
      }

      this.btnLoading = true;

      this.$store
        .dispatch("profile/UPDATE_PROFILE_PHOTO", this.data)
        .then(() => {
          this.btnLoading = false;
          this.data.image = "";
          this.$store.dispatch("profile/GET_PROFILE");
          //@ts-ignore
          this.$refs["profile-photo-modal"].hide();

          this.errors = {};
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      this.url = this.profile.profile_link;
    }
  }
});
