import { render, staticRenderFns } from "./CategoryComponent.vue?vue&type=template&id=301f1845&scoped=true"
import script from "./CategoryComponent.vue?vue&type=script&lang=ts"
export * from "./CategoryComponent.vue?vue&type=script&lang=ts"
import style0 from "./CategoryComponent.vue?vue&type=style&index=0&id=301f1845&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301f1845",
  null
  
)

export default component.exports