
import Vue from "vue";

export default Vue.extend({
  name: "MentoreeSignUpToMentor",
  components: {},
  props: { profile: Object },
  data() {
    return {
      data: {
        message: ""
      },
      errors: {},
      btnLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;
      this.$store
        .dispatch("profile/MENTEE_BECOME_MENTOR", this.data)
        .then(() => {
          this.btnLoading = false;
          //@ts-ignore
          this.$alertify.success((this as any).$t("setting.requestSent"));
          this.$router.push({
            name: "SignUpPageMentor"
          });
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //@ts-ignore
            this.$alertify.error(Object.values(this.errors)[0][0]);
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
